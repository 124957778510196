<template>
  <div>

    <b-card
      no-body
      class="mb-2"
    >

      <filter-slot

        :filter="filter"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="resetAllFilters"
        @reload="$refs['incomeList'].refresh()"
      >

        <b-table
          id="incomeList"
          slot="table"
          ref="incomeList"
          class="m-1"
          sticky-header="70vh"
          no-provider-filtering
          :busy.sync="isBusy"
          :items="search"
          :fields="filteredFields"
          :per-page="paginate.perPage"
          :current-page="paginate.currentPage"
          :filter="filter"
          show-empty
          small
        >
          <template #table-busy>
            <div class="text-center text-primary my-2">
              <b-spinner class="align-middle mr-1" />
              <strong>Loading ...</strong>
            </div>
          </template>
          <template v-slot:cell(date)="data">
            <div
              class="d-flex flex-column  justify-content-center align-items-start "
            >
              {{ data.item.income_date | myGlobal }}
            </div>
          </template>
          <template v-slot:cell(type)="data">
            <div
              class="d-flex flex-column  justify-content-center align-items-start "
            >
              {{ data.item.type }}
            </div>
          </template>
          <template v-slot:cell(no)="data">
            <div
              class="d-flex flex-column  justify-content-center align-items-start "
            >
              {{ data.item.doc_no | myGlobal }}
            </div>
          </template>
          <template v-slot:cell(customer)="data">
            <div
              class="d-flex flex-column  justify-content-center align-items-start "
            >
              {{
                data.item.print_on_check_as
                  ? data.item.display_name_as
                  : data.item.company
              }}
            </div>
          </template>

          <template v-slot:cell(amount)="data">
            <div
              class="d-flex flex-column  justify-content-center align-items-start "
            >
              <span>
                {{
                  data.item.currency
                }} {{
                  formatPrice(data.item.total)
                }}</span>
            </div>
          </template>
          <template v-slot:cell(created_by)="data">
            <div
              class="d-flex flex-column  justify-content-center align-items-start "
            >
              <span>

                <p>  {{ data.item.first_name }} {{
                  data.item.last_name
                }}</p>
                <p>    {{
                  data.item.created_at | myGlobalDay
                }}</p>
              </span>
            </div>
          </template>
          <template v-slot:cell(actions)="data">
            <div
              class="d-flex   justify-content-start align-items-start "
            >
              <feather-icon
                title="EDIT"
                icon="EditIcon"
                size="20"
                class="cursor-pointer text-warning"
                @click="editTransaction(data.item.id)"
              />
              <feather-icon
                title="DELETED"
                icon="Trash2Icon"
                size="20"
                class="cursor-pointer ml-1  text-danger"
                @click="openDeleteIncome(data.item)"
              />
            </div>
          </template>
        </b-table>

      </filter-slot>
      <new-income-modal
        v-if="newIncomeOn"
        :id-income="idToSend"
        @close="newIncomeOn=false"
        @reload="$refs['incomeList'].refresh()"
      />
    </b-card>
  </div>

</template>

<script>
import { mapGetters } from 'vuex'
import FilterSlot from '@/views/crm/views/sales-made/components/slots/FilterSlot.vue'
import dataFields from '@/views/management/views/companies/dashboard/incomes-module/incomes-list/fields.data'
import dataFilter from '@/views/management/views/companies/dashboard/incomes-module/incomes-list/filters.data'
import CompaniesService from '@/views/management/views/companies/companies.service'
import NewIncomeModal
from '@/views/management/views/companies/dashboard/incomes-module/modals/new-income-modal/NewIncomeModal'

export default {
  components: {
    FilterSlot,
    NewIncomeModal,
  },

  data() {
    return {
      idToSend: '',
      fields: dataFields,
      totalRows: 0,
      newIncomeOn: false,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilter,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,

    }
  },
  computed: {
    filteredFields() {
      return this.fields
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),

  },
  methods: {

    resetAllFilters() {
      this.filter.forEach(filter => {
        // eslint-disable-next-line no-param-reassign
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.incomeList.refresh()
    },
    resetSearch() {
      this.searchInput = ''
      this.$refs.incomeList.refresh()
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    async openDeleteIncome(data) {
      const params = {
        income_id: data.id,
        deleted_by: this.currentUser.user_id,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await CompaniesService.deleteIncomeById(params)

          this.$refs.incomeList.refresh()

          this.showSuccessSwal('Income has been deleted successfully')
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async search(ctx) {
      try {
        const params = {
          perpage: this.paginate.perPage,
          orderby: 1,
          order: 'desc',
          from: this.filter[0].model,
          to: this.filter[1].model,
          company_id: Number(this.$route.params.id),
          campo: this.filterPrincipal.model,
          filtercategory: this.filter[2].model ? (this.filter[2].model).toString() : this.filter[2].model,

        }
        const data = await CompaniesService.getAllIncomes(params, ctx.currentPage)

        this.items = data.data.data
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    editTransaction(id) {
      this.idToSend = id
      this.newIncomeOn = true
    },
  },

}
</script>

<style scoped>

</style>
