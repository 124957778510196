<template>
  <b-card class="border-top-primary border-3 border-table-radius px-0">
  <div>
    <customer-modal
      v-if="customersOn"
      @close="closeCustomersModal"
    />
    <new-income-modal
      v-if="newIncomeOn"
      @close="closeNewIncome"
      @reload="key++"
    />
    <product-and-services-modal
      v-if="productAndServicesOn"
      @close="closeProductAndServicesModal "
    />
    <b-row class="justify-content-end mr-1 mb-1">
      <b-button
        variant="success"
        @click="openProductAndServicesModal"
      ><feather-icon
        icon="PlusIcon"
        size="15"
        class="mr-50 text-white"
      />PRODUCTS & SERVICES
      </b-button>
      <b-button
        variant="success"
        class="ml-1"
        @click="openCustomersModal"
      ><feather-icon
        icon="PlusIcon"
        size="15"
        class="mr-50 text-white"
      />CUSTOMERS
      </b-button>
      <b-dropdown
        class="ml-1"
        no-caret
        right
        text="+ New"
        variant="success"
      >
        <template #button-content>
          <feather-icon
            icon="PlusIcon"
            size="15"
            class="mr-50 text-white"
          />NEW
        </template>
        <b-dropdown-item @click="openNewIncome">
          Invoices
        </b-dropdown-item>
      </b-dropdown>
    </b-row>

    <b-nav
      card-header
      pills
      class="m-0"
    >
      <b-nav-item
        exact
        :active="tab === 1"
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        @click="tab = 1"
      >INCOMES</b-nav-item>
      <b-nav-item
        exact
        :active="tab === 2"
        exact-active-class="active"
        :link-classes="['px-3',bgTabsNavs]"
        @click="tab = 2"
      >BANK TRANSFERS</b-nav-item>
    </b-nav>
    <b-card
      no-body
      class="border-top-primary border-3 border-table-radius px-0"
    >
      <incomes-list
        v-if="tab===1"
        :key="key"
      />
      <bank-transfer-list
        v-if="tab===2"
      />
    </b-card>

  </div>
  </b-card>
</template>

<script>

import CustomerModal from '@/views/management/views/companies/dashboard/incomes-module/modals/customers-modal/CustomerModal'
import NewIncomeModal from '@/views/management/views/companies/dashboard/incomes-module/modals/new-income-modal/NewIncomeModal'
import ProductAndServicesModal from '@/views/management/views/companies/dashboard/incomes-module/modals/products-and-services-modal/ProductAndServicesModal'
import BankTransferList from '@/views/management/views/companies/dashboard/incomes-module/bank-transfers-list/BankTransferList.vue'
import IncomesList from '@/views/management/views/companies/dashboard/incomes-module/incomes-list/IncomesList.vue'

export default {
  components: {
    CustomerModal,
    NewIncomeModal,
    ProductAndServicesModal,
    BankTransferList,
    IncomesList,
  },
  data() {
    return {
      productAndServicesOn: false,
      customersOn: false,
      newIncomeOn: false,
      key: 1,
      tab: 1,
    }
  },
  methods: {
    openProductAndServicesModal() {
      this.productAndServicesOn = true
    },
    openCustomersModal() {
      this.customersOn = true
    },
    openNewIncome() {
      this.newIncomeOn = true
    },
    closeProductAndServicesModal() {
      this.productAndServicesOn = false
    },
    closeCustomersModal() {
      this.customersOn = false
    },
    closeNewIncome() {
      this.newIncomeOn = false
    },
  },

}
</script>

<style scoped>
</style>
