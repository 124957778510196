export default [
  {
    key: 'date',
    sortable: false,
    label: 'Date',
  },
  {
    key: 'type',
    sortable: false,
    label: 'TYPE',
  },
  {
    key: 'no',
    sortable: false,
    label: 'NO.',
  },
  {
    key: 'customer',
    sortable: false,
    label: 'CUSTOMER',
  },

  {
    key: 'amount',
    sortable: false,
    label: 'AMOUNT',
  },
  {
    key: 'created_by',
    sortable: false,
    label: 'CREATED BY',
  },

  {
    key: 'actions',
    sortable: false,
    label: 'Actions',
  },

]
