<template>
  <div class="ml-2 mr-2">
    <div class="d-flex justify-content-between">
      <h1
        class="
                      pt-1"
      >
        Service
      </h1>
      <feather-icon
        class="pt-2  cursor-pointer"
        icon="CornerUpLeftIcon"
        size="45"
        @click="onReturn"
      />

    </div>
    <div>
      <b-card>
        <validation-observer ref="form">
          <b-row>
            <b-col>
              <b-form-group
                id="name-1"
                label="Name"
                label-for="name-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="name-1"
                  v-model="service.name"

                  trim
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="sku-1"
                label="SKU"
                label-for="sku-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  class="pr-1"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="name-1"
                  v-model="service.sku"

                  trim
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col md="11">
              <b-form-group
                id="Category-1"
                label="Category"
                label-for="Category-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  class="pr-1"

                  type="input"
                />
                <v-select
                  v-if="finishedLoading"
                  v-model="service.category_id"
                  label="name"
                  :reduce="val=>val.id"
                  :options="optionsCategory"
                />

              </b-form-group>
            </b-col>
            <b-col md="1">
              <feather-icon
                icon="PlusCircleIcon"
                class="d-flex justify-content-center align-content-center align-items-center cursor-pointer"
                style="margin-top: 28px"
                size="27"
                @click="openCategoryModal"
              /></b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="note-1"
                label="Description"
                label-for="note-1"
              >

                <b-form-checkbox
                  id="sell_to_customers-1"
                  v-model="service.sell_to_customers"
                  name="sell_to_customers-1"
                >
                  I sell this product/service to my customers.
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="note-1"

                label-for="note-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <b-form-textarea
                  v-if="finishedLoading"
                  id="note-1"
                  v-model="service.sale_description"

                  trim
                />
              </b-form-group>
            </b-col>

          </b-row>
          <b-row>
            <b-col md="4">       <b-form-group
              id="sales_price-1"
              label="Sales price/rate"
              label-for="note-1"
            >
              <b-skeleton
                v-if="!finishedLoading"
                class="pr-1"

                type="input"
              />
              <b-form-input
                v-if="finishedLoading"
                id="name-1"
                v-model="service.price"
                v-mask="'################################'"

                type="number"
                trim
              />
            </b-form-group></b-col>
            <b-col md="8">
              <b-form-group
                id="incomes_account-1"
                label="Income account"
                label-for="incomes_account-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <v-select
                  v-if="finishedLoading"
                  v-model="service.income_account_id"
                  label="name"
                  :reduce="val=>val.id"
                  :options="parentAccounts"
                >
                  <template

                    #selected-option="{ name , account_type_text}"
                  >
                    <b-row>
                      <b-col
                        class="d-flex justify-content-start"
                        md="9"
                      >
                        {{ name }}
                      </b-col>

                      <b-col
                        class="d-flex justify-content-end align-content-end "
                        md="3"
                      >
                        {{ account_type_text }}
                      </b-col>
                    </b-row>
                  </template>
                  <template

                    #option="{ name , account_type_text }"
                  >

                    <b-row>
                      <b-col
                        class="d-flex justify-content-start"
                        md="9"
                      >
                        {{ name }}
                      </b-col>

                      <b-col
                        class="d-flex justify-content-end align-content-end "
                        md="3"
                      >
                        {{ account_type_text }}
                      </b-col>
                    </b-row>

                  </template>
                </v-select>
              </b-form-group></b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="note-1"
                label="Purchasing information"
                label-for="note-1"
              >

                <b-form-checkbox
                  :id="`purchase_from_supplier-1${idservice}`"

                  v-model="service.purchase_from_supplier"

                  :name="`purchase_from_supplier-1${idservice}`"
                >
                  I purchase this product/service from a supplier.
                </b-form-checkbox>
              </b-form-group>
            </b-col>

          </b-row>

          <template v-if="service.purchase_from_supplier">
            <b-row>
              <b-col>
                <b-form-group
                  id="purchase_description-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="pr-1"

                    type="input"
                  />
                  <b-form-textarea
                    v-if="finishedLoading"
                    id="note-1"
                    v-model="service.purchase_description"

                    trim
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row>
              <b-col md="4">       <b-form-group
                id="Cost-1"
                label="Cost"
                label-for="Cost-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"
                  class="pr-1"

                  type="input"
                />
                <b-form-input
                  v-if="finishedLoading"
                  id="name-1"
                  v-model="service.cost"
                  v-mask="'################################'"
                  type="number"
                  trim
                />

              </b-form-group></b-col>
              <b-col md="8">          <b-form-group
                id="expense_account_id-1"
                label="Expense Account"
                label-for="expense_account_id-1"
              >
                <b-skeleton
                  v-if="!finishedLoading"

                  type="input"
                />
                <v-select
                  v-if="finishedLoading"
                  v-model="service.expense_account_id"
                  label="name"
                  :reduce="val=>val.id"
                  :options="parentAccounts"
                >
                  <template

                    #selected-option="{ name , account_type_text}"
                  >
                    <b-row>
                      <b-col
                        class="d-flex justify-content-start"
                        md="9"
                      >
                        {{ name }}
                      </b-col>

                      <b-col
                        class="d-flex justify-content-end align-content-end "
                        md="3"
                      >
                        {{ account_type_text }}
                      </b-col>
                    </b-row>
                  </template>
                  <template

                    #option="{ name , account_type_text }"
                  >

                    <b-row>
                      <b-col
                        class="d-flex justify-content-start"
                        md="9"
                      >
                        {{ name }}
                      </b-col>

                      <b-col
                        class="d-flex justify-content-end align-content-end "
                        md="3"
                      >
                        {{ account_type_text }}
                      </b-col>
                    </b-row>

                  </template>
                </v-select>
              </b-form-group></b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  id="vendor-1"
                  label="Preferred Supplier"
                  label-for="vendor-1"
                >
                  <b-skeleton
                    v-if="!finishedLoading"
                    class="pr-1"

                    type="input"
                  />
                  <v-select
                    v-if="finishedLoading"
                    v-model="service.vendor_id"
                    label="display_name_as"
                    :reduce="val=>val.id"
                    :calculate-position="withPopper"
                    :options="optionsVendors"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </template>
          <Category
            v-if="categoryModalOn"
            @refresh="getAllCategorys"
            @close="closeCategoryModal"
          />
        </validation-observer>

        <b-card-footer>
          <div
            class="d-flex   justify-content-end align-items-end  "
          >
            <b-dropdown
              split
              text="SAVE AND CLOSE"
              class="m-2"
              @click="saveServiceClose()"
            >

              <b-dropdown-item @click="saveServiceNew">
                SAVE AND NEW
              </b-dropdown-item>
            </b-dropdown>

          </div>
        </b-card-footer>
      </b-card>
    </div>

  </div>
</template>

<script>
import Category
from '@/views/management/views/companies/dashboard/incomes-module/modals/products-and-services-modal/modals/service/modals/Category.vue'
import CompaniesService from '@/views/management/views/companies/companies.service'
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'

import { mapGetters } from 'vuex'

export default {
  components: {
    Category,
    vSelect,
  },
  props: {
    producAndServiceController: {
      type: Number,
      default: null,
    },
    edit: {
      type: Boolean,
      default: null,
    },
    idservice: {
      type: Number,
      default: null,
    },

  },

  data() {
    return {
      finishedLoading: true,
      service: {
        name: '',
        category_id: '',
        income_account_id: null,
        expense_account_id: null,
        purchase_from_supplier: false,
        vendor_id: null,
        sell_to_customers: true,
      },
      categoryModalOn: false,
      optionsCategory: [],
      parentAccounts: [],
      optionsVendors: [],
      spinnerOn: false,
    }
  },

  async created() {
    this.finishedLoading = false
    await Promise.all([

      this.getAllCategorys(),
      this.getParentsAccount(),
      this.getAllVendors(),
    ])

    if (this.edit === true) {
      await this.getServiceAndProduct()
    }
    this.finishedLoading = true
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),

  },
  methods: {

    async getServiceAndProduct() {
      try {
        const params = {
          product_id: this.idservice,
        }
        const data = await CompaniesService.getServiceAndProductbyId(params)
        const datita = data.data[0]
        this.service.name = datita.name ? datita.name : ''
        this.service.sku = datita.sku
        this.service.category_id = datita.category
        this.service.sell_to_customers = datita.sell_to_my_customers === 1 ? datita.sell_to_my_customers = true : datita.sell_to_my_customers = false
        this.service.price = datita.price
        this.service.image = datita.image
        this.service.sale_description = datita.sale_description
        this.service.income_account_id = datita.income_account
        this.service.purchase_from_supplier = datita.from_supplier === 1 ? datita.from_supplier = true : datita.from_supplier = false
        this.service.cost = datita.cost
        this.service.expense_account_id = datita.expense_account
        this.service.vendor_id = datita.vendor

        this.service.purchase_description = data.data[0].pucharse_description
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },

    async saveServiceClose() {
      try {
        this.spinnerOn = true
        const result = await this.$refs.form.validate()
        if (result === false) {
          this.spinnerOn = false
        }
        if (result) {
          if (this.service.purchase_from_supplier === false) {
            this.service.cost = null
            this.service.purchase_description = null

            this.service.expense_account_id = null
            this.service.vendor_id = null
          }
          const params = {
            name: this.service.name,
            sku: this.service.sku,
            category_id: this.service.category_id,
            sell_to_customers: this.service.sell_to_customers === true ? this.service.sell_to_customers = 1 : this.service.sell_to_customers = 0,
            price: this.service.price,
            image: this.service.image,
            sale_description: this.service.sale_description,
            income_account_id: this.service.income_account_id,
            purchase_from_supplier: this.service.purchase_from_supplier === true ? this.service.purchase_from_supplier = 1 : this.service.purchase_from_supplier = 0,
            cost: this.service.cost,
            expense_account_id: this.service.expense_account_id,
            vendor_id: this.service.vendor_id,
            created_by: this.currentUser.user_id,
            company_id: Number(this.$route.params.id),
            type: 3,
            product_id: this.idservice,
            purchase_description: this.service.purchase_description,
          }

          const data = await CompaniesService.saveService(params)
          if (data.status === 200) {
            this.spinnerOn = false
            this.service = {}
            this.$emit('refresh')
            this.$emit('close')
            this.showSuccessSwal()
          }
        }
      } catch (e) {
        this.spinnerOn = false
        this.showErrorSwal(e)
        return []
      }
    },

    async saveServiceNew() {
      try {
        this.spinnerOn = true
        const result = await this.$refs.form.validate()
        if (result === false) {
          this.spinnerOn = false
        }
        if (result) {
          if (this.service.purchase_from_supplier === false) {
            this.service.cost = null
            this.service.purchase_description = null

            this.service.expense_account_id = null
            this.service.vendor_id = null
          }
          const params = {
            name: this.service.name,
            sku: this.service.sku,
            category_id: this.service.category_id,
            sell_to_customers: this.service.sell_to_customers === true ? this.service.sell_to_customers = 1 : this.service.sell_to_customers = 0,
            price: this.service.price,
            image: this.service.image,
            sale_description: this.service.sale_description,
            income_account_id: this.service.income_account_id,
            purchase_from_supplier: this.service.purchase_from_supplier === true ? this.service.purchase_from_supplier = 1 : this.service.purchase_from_supplier = 0,
            cost: this.service.cost,
            expense_account_id: this.service.expense_account_id,
            vendor_id: this.service.vendor_id,
            created_by: this.currentUser.user_id,
            company_id: Number(this.$route.params.id),
            type: 3,
            product_id: this.idservice,
            purchase_description: this.service.purchase_description,
          }

          const data = await CompaniesService.saveService(params)
          if (data.status === 200) {
            this.spinnerOn = false
            this.service = {}
            this.$emit('refresh')
            const serviceEmpty = {
              name: '', category_id: '', income_account_id: null, expense_account_id: null, purchase_from_supplier: false, vendor_id: null,

            }
            this.service = serviceEmpty
            this.showSuccessSwal()
          }
        }
      } catch (e) {
        this.spinnerOn = false
        this.showErrorSwal(e)
        return []
      }
    },

    withPopper(dropdownList, component, { width }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top',
              )
            },
          },
        ],
      })
      return () => popper.destroy()
    },
    async getAllCategorys() {
      try {
        const params = {
          company_id: this.$route.params.id,
        }
        const data = await CompaniesService.getManProductServiceCategory(params)

        // eslint-disable-next-line no-param-reassign

        this.optionsCategory = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.optionsCategory
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async getParentsAccount() {
      try {
        const params = {
          company_id: this.$route.params.id,
        }
        const data = await CompaniesService.getParentsAccount(params)

        // eslint-disable-next-line no-param-reassign

        this.parentAccounts = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.parentAccounts
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    async getAllVendors() {
      try {
        const params = {
          idcompany: Number(this.$route.params.id),
        }
        const data = await CompaniesService.getAllVendors(params)

        // eslint-disable-next-line no-param-reassign

        this.optionsVendors = data.data
        // Must return an array of items or an empty array if an error occurred

        return this.optionsVendors
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    onReturn() {
      this.$emit('return')
    },
    openCategoryModal() {
      this.categoryModalOn = true
    },
    closeCategoryModal() {
      this.categoryModalOn = false
    },

  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.v-select.drop-up.vs--open .vs__dropdown-toggle {
  border-radius: 0 0 4px 4px;
  border-top-color: transparent;
  border-bottom-color: rgba(60, 60, 60, 0.26);
}

[data-popper-placement="top"] {
  border-radius: 4px 4px 0 0;
  border-top-style: solid;
  border-bottom-style: none;
  box-shadow: 0 -3px 6px rgba(0, 0, 0, 0.15);
}

#overpx .vs__dropdown-toggle .vs__selected-options .vs__search {
  margin: 9.15px 0 0 !important;
}

</style>
