<template>
  <b-sidebar
    v-model="ownControl"
    shadow
    backdrop
    width="600px"
    right
    bg-variant="white"
    no-close-on-backdrop
    title="Product/Service information"
    @hidden="closeModal"
  >
    <template v-if="producAndServiceController === 0">
      <b-list-group flush>
        <b-list-group-item
          v-for="(prod, index) in productsAndServices2"
          v-if="prod.ocultar===1"
          :key="index"
          style="display: flex; align-items: center"
          class="cursor-pointer"
          @click="navigateToProductOrService(index + 1)"
        >

          <span

            style="margin-right: auto"
          >
            <b-avatar
              size="84px"
              variant="primary"
            >
              <template #default>
                <amg-icon
                  style=" position: absolute;
  left:29.5%;
  top: 32%;"
                  size="50"
                  icon="ServiceProduct"
                />
              </template>
            </b-avatar>

          </span>

          <div

            style="width: 410px"
          >
            <h5>
              <strong>{{ prod.title }}</strong>
            </h5>
            <p>{{ prod.description }}</p>
          </div>
        </b-list-group-item>
      </b-list-group>
    </template>
    <template v-if="producAndServiceController === 1 ">
      <div>
        <div class="d-flex justify-content-between">
          <h1
            class=" pl-3
                      pt-1"
          >
            Inventory
          </h1>
          <feather-icon
            class="pt-2 pr-1 cursor-pointer"
            icon="CornerUpLeftIcon"
            size="45"
            @click="turnBack"
          />

        </div>

      </div>
    </template>
    <template v-if="producAndServiceController === 2">
      <div>
        <div class="d-flex justify-content-between">
          <h1
            class=" pl-3
                      pt-1"
          >
            Non-inventory

          </h1>
          <feather-icon
            class="pt-2 pr-1 cursor-pointer"
            icon="CornerUpLeftIcon"
            size="45"
            @click="turnBack"
          />

        </div>

      </div>
    </template>
    <template v-if="producAndServiceController === 3">
      <div>
        <service
          :edit="edit"
          :idservice="service"
          @return="turnBack"
          @refresh="refresh"
          @close="closeModal"
        />

      </div>
    </template>
    <template v-if="producAndServiceController === 4">
      <div>
        <div class="d-flex justify-content-between">
          <h1
            class=" pl-3
                      pt-1"
          >
            Bundle
          </h1>
          <feather-icon

            class="pt-2 pr-1 cursor-pointer"
            icon="CornerUpLeftIcon"
            size="45"
            @click="turnBack"
          />

        </div>

      </div>
    </template>

  </b-sidebar>
</template>

<script>
import Service from '@/views/management/views/companies-old/dashboard/incomes-module/modals/products-and-services-modal/modals/service/CreateServiceOfProductAndServices.vue'

export default {
  components: {
    Service,
  },
  props: {

    edit: {
      type: Boolean,
      default: null,
    },
    service: {
      type: Number,
      default: null,
    },
    types: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      ocultar: true,
      ownControl: false,
      productsAndServices2: [
        {
          icon: 'fas fa-tshirt',
          title: 'Inventory',
          description:
              'Products you buy and/or sell and that you track quantities of.',
          disabled: true,
          ocultar: 2,
        },
        {
          icon: 'fas fa-box-open',
          title: 'Non-inventory',
          description:
              "Products you buy and/or sell but you don't need to (or can't) track quantities of, for example, nuts and bolts used in a installation.",
          disabled: true,
          ocultar: 2,
        },
        {
          icon: 'service-product',
          title: 'Service',
          description:
              'Services that you provide to customers, for example, landscaping or tax preparation services.',
          disabled: false,
          ocultar: 1,
        },
        {
          icon: 'fas fa-file-archive',
          title: 'Bundle',
          description:
              'A collection of products and/or services that you sell together, for example, a gift basket of fruit, cheese, and wine.',
          disabled: true,
          ocultar: 2,
        },
      ],
      producAndServiceController: 0,
    }
  },
  created() {
    if (this.edit === true) {
      this.navigateToProductOrService(this.types)
    } else {
      this.producAndServiceController = 0
    }
  },
  mounted() {
    this.ownControl = true
  },

  methods: {
    refresh() {
      this.$emit('refresh')
    },
    navigateToProductOrService(controller) {
      this.producAndServiceController = controller
    },
    closeModal() {
      this.$emit('close')
    },
    turnBack() {
      this.producAndServiceController = 0
    },
  },
}
</script>

<style scoped>

</style>
