<template>
  <b-modal
    v-model="ownControl"

    hide-footer
    title-class="h3 text-white font-weight-bolder"
    title="PRODUCTS AND SERVICES"
    size="xmd"
    @hidden="closeModal"
  >
    <div>
      <div class="d-flex justify-content-end mb-1">
        <b-button
          variant="success"
          @click="openCreateModal(false,null)"
        ><feather-icon
            icon="PlusIcon"
            size="15"
            class="text-white mr-1"
        />CREATE
        </b-button>
      </div>
      <b-card
        no-body

        class="mb-0"
      >
        <filter-slot

          :filter="filter"
          :filter-principal="filterPrincipal"
          :total-rows="totalRows"
          :paginate="paginate"
          :start-page="startPage"
          :to-page="toPage"
          @reset-all-filters="resetAllFilters"
          @reload="$refs['productAndServicesList'].refresh()"
        >

          <b-table

            id="productAndServicesList"
            slot="table"
            ref="productAndServicesList"
            class="m-1"
            sticky-header="70vh"
            no-provider-filtering
            :busy.sync="isBusy"
            :items="search"
            :fields="filteredFields"
            :per-page="paginate.perPage"
            :current-page="paginate.currentPage"
            :filter="filter"
            show-empty
            small
          >

            <template #table-busy>
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle mr-1" />
                <strong>Loading ...</strong>
              </div>
            </template>

            <template v-slot:cell(category)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.category_name }}
              </div>

            </template>

            <template v-slot:cell(name)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.name }}
              </div>

            </template>
            <template v-slot:cell(sku)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.sku }}
              </div>

            </template>
            <template v-slot:cell(type)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.type }}
              </div>

            </template>
            <template v-slot:cell(sales_pricing)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.price }}
              </div>

            </template>
            <template v-slot:cell(cost)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                {{ data.item.cost }}
              </div>

            </template>
            <template v-slot:cell(created_by)="data">
              <div
                class="d-flex flex-column  justify-content-center align-items-start "
              >
                <span>

                  <p>  {{ data.item.first_name }} {{
                    data.item.last_name
                  }}</p>
                  <p>    {{
                    data.item.created_at | myGlobalDay
                  }}</p>
                </span>
              </div>
            </template>
            <template v-slot:cell(actions)="data">
              <div
                class="d-flex   justify-content-start align-items-start "
              >
                <feather-icon
                  title="EDIT"
                  icon="EditIcon"
                  size="20"
                  class="cursor-pointer text-warning"
                  @click="openCreateModal(true,data.item)"
                />
                <feather-icon
                  title="DELETED"
                  icon="Trash2Icon"
                  size="20"
                  class="cursor-pointer ml-1  text-danger"
                  @click="deletProductAndServices(data.item)"
                />
              </div>
            </template>
          </b-table>
        </filter-slot>
      </b-card>
      <product-and-service-information
        v-if="productAndServiceOn"
        :types="type"
        :edit="editService"
        :service="itemIdService"
        @refresh="resetSearch"
        @close="closeCreateModal"
      />
    </div>
  </b-modal>
</template>
<script>
import dataFields
from '@/views/management/views/companies-old/dashboard/incomes-module/modals/products-and-services-modal/fields.data'

import dataFilter
from '@/views/management/views/companies-old/dashboard/incomes-module/modals/products-and-services-modal/filters.data'
import { mapGetters } from 'vuex'
import CompaniesService from '@/views/management/views/companies-old/companies.service'
import ProductAndServiceInformation
from '@/views/management/views/companies-old/dashboard/incomes-module/modals/products-and-services-modal/modals/ProductAndServiceInformation.vue'

export default {
  components: {

    ProductAndServiceInformation,
  },
  data() {
    return {
      fields: dataFields,
      totalRows: 0,
      filterPrincipal: {
        type: 'input',
        inputType: 'text',
        placeholder: 'Search...',
        model: '',
      },
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      isBusy: false,
      basicSearch: true,
      filter: dataFilter,
      startPage: null,
      toPage: null,
      items: [],
      baseImg: process.env.VUE_APP_BASE_URL_FRONT,
      ownControl: false,
      comments: null,
      productAndServiceOn: false,
      servicesOn: false,
      editService: false,
      itemIdService: null,
      type: null,
    }
  },
  computed: {
    filteredFields() {
      return this.fields
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
      token: 'auth/token',

    }),

  },
  created() {
    this.ownControl = true
  },

  methods: {
    async deletProductAndServices(data) {
      const params = {
        service_id: data.id,
        deleted_by: this.currentUser.user_id,

      }
      try {
        const response = await this.showConfirmSwal()
        if (response.isConfirmed) {
          await CompaniesService.deleteProductsAndServicesById(params)

          this.$refs.productAndServicesList.refresh()

          this.showSuccessSwal('Product and Services has been deleted successfully')
        }
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
    closeModal() {
      this.$emit('close')
    },
    openCreateModal(edit, item) {
      if (edit === true) {
        this.editService = edit
        this.itemIdService = item.id

        if (item.type === 'SERVICE') {
          this.type = 3
        }
        this.productAndServiceOn = true
      }
      if (edit === false) {
        this.editService = edit
        this.itemIdService = null
        this.productAndServiceOn = true
      }
    },
    closeCreateModal() {
      this.productAndServiceOn = false
    },

    resetAllFilters() {
      this.filter.forEach(filter => {
        // eslint-disable-next-line no-param-reassign
        filter.model = null
      })
      this.filterPrincipal.model = null
      this.$refs.productAndServicesList.refresh()
    },
    resetSearch() {
      this.searchInput = ''
      this.$refs.productAndServicesList.refresh()
    },
    formatPrice(value) {
      const val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },

    async search(ctx) {
      try {
        const params = {
          perpage: this.paginate.perPage,
          orderby: 1,
          order: 'desc',
          from: this.filter[0].model,
          to: this.filter[1].model,
          company_id: Number(this.$route.params.id),
          campo: this.filterPrincipal.model,

        }
        const data = await CompaniesService.getAllManProductsServices(params, ctx.currentPage)

        this.items = data.data.data
        // Must return an array of items or an empty array if an error occurred

        this.startPage = data.data.from
        this.paginate.currentPage = data.data.current_page
        this.paginate.perPage = data.data.per_page
        this.nextPage = this.startPage + 1
        this.endPage = data.data.last_page
        this.totalData = data.data.total
        this.totalRows = data.data.total
        this.toPage = data.data.to

        return this.items
      } catch (e) {
        this.showErrorSwal(e)
        return []
      }
    },
  },

}
</script>

<style scoped>

</style>
