<template>
  <b-modal
    id="modal-closing"
    ref="modal"
    v-model="onControl"
    :header-bg-variant=" isDarkSkin?'dark' : 'light'"
    modal-class="tree-modal modal-primary transaction-modal"
    body-class="p-0"
    title-tag="h3"
    title-class="h3 text-dark font-weight-bolder"
    footer-bg-variant="dark"
    scrollable
    hide-header
    @hidden="close"
  >
    <b-row
      style="width: 100vw !important"
      class="m-0 px-1 pt-2"
      :class="isDarkSkin? 'bg-dark' : 'bg-light'"
    >
      <div
        style="width: 100% !important"
        class="d-flex justify-content-between"
      >
        <h2 class="d-inline text-dark font-weight-bolder mb-2 ml-1">
          INVOICE {{ invoice.doc_no ? "no." + invoice.doc_no : "" }}
        </h2>
        <div
          style="background: white !important;border: none; width: 30px !important; height: 30px !important;"
          class=" bg-white py-0 cursor-pointer rounded close-but"
          @click="close()"
        >
          <feather-icon
            class="text-primary"
            style="margin: 5px !important"
            icon="XIcon"
            size="18"
          />
        </div>
      </div>
      <b-row
        class="m-0 mt-1 mb-1"
        style="width: 100% !important"
      >
        <b-col md="9">
          <b-row>
            <b-col md="3">
              <span class="d-flex justify-content-between mb-half">
                <span>Customer</span>
                <feather-icon
                  icon="PlusCircleIcon"
                  size="22"
                  class="text-primary cursor-pointer"
                  @click="customerModalController=true"
                />
              </span>
              <v-select
                v-model="invoice.customer_id"
                class="bg-white"
                placeholder="Select option"
                :reduce="company => company.id"
                :options="customers"
                label="company"
                :disabled="isConfirmed"
                @input="onChangeCustomer"
              >
                <template #selected-option="{ print_on_check_as, display_name_as, company}">
                  <span>{{ print_on_check_as ? display_name_as : company }}</span>
                </template>
                <template #option="{ print_on_check_as, display_name_as, company}">
                  <span>{{ print_on_check_as ? display_name_as : company }}</span>
                </template>
              </v-select>
            </b-col>
            <b-col md="3">
              <span class="d-flex justify-content-between mb-half">
                <span>Customer email</span>
              </span>
              <b-form-input
                v-model="invoice.customer_email"
                type="email"
                :disabled="isConfirmed"
              />
            </b-col>
            <b-col
              xl="2"
              md="3"
            >
              <b-form-group
                label="Currency"
              >
                <b-form-select
                  v-model="invoice.currency"
                  class="bg-white"
                  :options="currencies"
                  :disabled="isConfirmed || isCompanyFromUsa"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <span class="d-flex justify-content-between mb-half">
                <span>Invoice no.</span>
              </span>
              <b-form-input
                v-model="invoice.doc_no"
                :disabled="isConfirmed"
              />
            </b-col>
          </b-row>
          <b-row>
            <b-col md="3">
              <span class="d-flex justify-content-between mb-half">
                <span>Billing address</span>
              </span>
              <b-form-textarea
                v-model="invoice.billing_address"
                style="height: inherit"
                no-resize
                :disabled="isConfirmed"
                type="email"
              />
            </b-col>
            <b-col md="3">
              <span class="d-flex justify-content-between mb-half">
                <span>Terms</span>
                <feather-icon
                  icon="PlusCircleIcon"
                  size="22"
                  class="text-primary cursor-pointer"
                  @click="termsModalController = true"
                />
              </span>
              <v-select
                v-model="invoice.term_id"
                class="bg-white"
                :reduce="name => name.id"
                :options="terms"
                label="name"
                :disabled="isConfirmed"
              />
            </b-col>
            <b-col
              xl="2"
              md="3"
            >
              <span class="d-flex justify-content-between mb-half">
                <span>Invoice date</span>
              </span>
              <b-form-datepicker
                v-model="invoice.income_date"
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en"
                :disabled="isConfirmed"
                placeholder=""
              />
            </b-col>
            <b-col
              xl="2"
              md="3"
            >
              <span class="d-flex justify-content-between mb-half">
                <span>Due date</span>
              </span>
              <b-form-datepicker
                v-model="invoice.due_date"
                placeholder=""
                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                locale="en"
                :disabled="isConfirmed"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="3"
          class="mt-1"
        >
          <div class="text-right mb-1 font-weight-bolder">
            Balance Due
          </div>
          <money
            v-if="money"
            :value="totalAmount"
            class="input-money-style input-money-specific text-right text-secondary font-weight-bolder"
            style="height: 40px;font-size: 30pt; width: 100%;"
            disabled
            v-bind="money"
          />
        </b-col>
      </b-row>
    </b-row>

    <b-table-simple
      ref="expensesCategories"
      class="mx-1 mt-1"
      responsive="sm"
    >
      <b-thead>
        <b-tr>
          <b-th>#</b-th>
          <b-th class="d-flex justify-content-between">
            <span>PRODUCT/SERVICE
              <span
                v-b-tooltip.hover.top="'Keep track of what you sold and when. You can set up these categories anytime.'"
                class="cursor-pointer"
              >(?)</span>
            </span>
            <feather-icon
              icon="PlusCircleIcon"
              size="22"
              class="text-primary cursor-pointer"
              @click="categorySidebarController = true"
            />
          </b-th>
          <b-th>DESCRIPTION</b-th>
          <b-th>QTY</b-th>
          <b-th>RATE</b-th>
          <b-th>AMOUNT</b-th>
          <b-th />
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(line, index) in invoice.detail"
          :key="index"
        >
          <b-td>{{ index +1 }}</b-td>
          <b-td style="width: 30% !important">
            <b-skeleton style="width: 30% !important" v-if="isLoading" type="input"></b-skeleton>
            <v-select
              v-else
              v-model="line.service"
              :options="productsAndServices"
              :reduce="name => name.id"
              label="name"
              :disabled="isConfirmed"
              @input="onChangeProducts(line)"
            >
              <template
                #selected-option-container="{ option}"
              >
                <div class="vs__selected">
                  <span v-if="Object.keys(option).length>1">{{option.name}}</span>
                  <span v-else>This service was deleted</span>
                </div>
              </template>
            </v-select>
          </b-td>
          <b-td>
            <b-skeleton v-if="isLoading" type="input"></b-skeleton>
            <b-form-input
              v-else
              v-model="line.description"
              :disabled="isConfirmed"
            />
          </b-td>
          <b-td>
            <b-skeleton v-if="isLoading" type="input"></b-skeleton>
            <b-form-input
              v-else
              v-model="line.quantity"
              :disabled="isConfirmed"
              @input="generateTotal(line)"
            />
          </b-td>
          <b-td>
            <b-skeleton v-if="isLoading" type="input"></b-skeleton>
            <b-form-input
              v-else
              v-model="line.rate"
              type="number"
              :disabled="isConfirmed"
              @input="generateTotal(line)"
            />
          </b-td>
          <b-td>
            <b-skeleton v-if="isLoading" type="input"></b-skeleton>
            <template v-if="money && !isLoading">
              <money
                v-model="line.amount"
                class="form-control"
                v-bind="money"
                :disabled="isConfirmed"
              />
            </template>
          </b-td>
          <b-td class="text-right">
            <feather-icon
              v-if="!isConfirmed"
              icon="TrashIcon"
              class="text-danger cursor-pointer"
              size="23"
              @click="index > 1 ? deleteInvoiceDetail(index, line) : null"
            />
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div
      class="d-flex justify-content-between mr-2"
      style="width: 100%"
    >
      <div>
        <b-button
          variant="light"
          class="mx-1"
          :disabled="isConfirmed"
          @click="addLines"
        >
          Add Lines
        </b-button>
        <b-button
          variant="light"
          :disabled="isConfirmed"
          @click="clearAllLines"
        >
          Clear all lines
        </b-button>
      </div>
      <div v-if="money">
        <span class="font-medium-4 text-right">Total</span>
        <money
          :value="totalAmount"
          class="p-0 border-0 ml-1 mr-1 font-weight-bolder text-secondary text-right"
          style="font-size: 18px; background: transparent"
          v-bind="money"
          disabled
        />
      </div>
    </div>
    <b-row
      class="mt-2 ml-1 d-flex justify-content-between"
      style="width: 100%;height: 80px"
    >
      <b-col
        xl="5"
        md="12"
      >
        <b-form-group
          label="Message on invoice"
        >
          <b-form-textarea
            v-model="invoice.message_on_invoice"
            placeholder="This will show up on the invoice."
            style="width: 100%; height: 117px"
            :disabled="isConfirmed"
          />
        </b-form-group>
        <b-form-group
          label="Message on statement"
        >
          <b-form-textarea
            v-model="invoice.message_on_statement"
            placeholder="If you send statements to customers, this will show up as the description for this invoice."
            style="width: 100%; height: 117px"
            :disabled="isConfirmed"
          />
        </b-form-group>
      </b-col>
      <b-col
        v-if="!isConfirmed"
        xl="7"
        md="12"
      >
        <b-col md="12">
          <b-row>
            <b-col md="6">
              <div class="mb-half">
                Attachments
              </div>
            </b-col>
            <b-col md="6">
              <div class="mb-half">
                Maximum size: 20MB
              </div>
            </b-col>
          </b-row>
          <drag-and-drop
            ref="file-manager"
            v-model="files"
            source="income"
            :files-array="files"
            :files-to-show="invoice.files"
            @delete-file="deleteFile"
          />
        </b-col>
      </b-col>
    </b-row>

    <template #modal-footer="{cancel}">
      <div
        style="width: 100%;"
        class="d-flex justify-content-between"
      >
        <div>
          <b-button
            class="mr-1"
            @click="cancel()"
          >
            Cancel
          </b-button>
          <b-button
            v-if="invoice.status == null"
            @click="clearData"
          >
            Clear
          </b-button>
        </div>
        <b-dropdown
          dropup
          variant="primary"
          split-variant="outline-primary"
          split
          text="Save and Close"
          @click="upload"
        >
          <b-dropdown-item @click="upload(1)">
            Save and new
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </template>
    <create-term-modal
      v-if="termsModalController"
      :company-id="idCompany"
      @close="termsModalController = false"
      @reload="getTerms"
    />
    <create-customer
      v-if="customerModalController"
      @close="customerModalController = false"
      @refresh="getCustomers"
    />
    <product-and-service-information
      v-if="categorySidebarController"
      :types="3"
      :edit="false"
      :service="null"
      @refresh="getProductsAndServices"
      @close="categorySidebarController = false"
    />
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import CreateTermModal from '@/views/management/views/companies/dashboard/expenses-module/modals/CreateTermModal.vue'
import CreateCustomer from '@/views/management/views/companies/dashboard/incomes-module/modals/customers-modal/modals/CreateCustomer.vue'
import { mapGetters } from 'vuex'
import CompaniesService from '@/views/management/views/companies/companies.service'
import moment from 'moment'
import CategoriesService from '@/views/management/views/categories/categories.service'
import dragAndDrop from '@/views/commons/utilities/DragAndDrop'
import ProductAndServiceInformation from '@/views/management/views/companies/dashboard/incomes-module/modals/products-and-services-modal/modals/ProductAndServiceInformation'

export default {
  components: {
    vSelect,
    CreateTermModal,
    CreateCustomer,
    dragAndDrop,
    ProductAndServiceInformation,
  },
  props: {
    idIncome: null,
  },
  data() {
    return {
      onControl: false,
      productsAndServices: [],
      idDrag: 1,
      deleteds: [],
      invoice: {
        income_id: null,
        company_id: '',
        customer_id: null,
        payment_account: null,
        income_date: null,
        due_date: null,
        payment_method: null,
        term_id: null,
        doc_no: null,
        billing_address: null,
        message_on_invoice: null,
        message_on_statement: null,
        type: 1,
        status: null,
        customer_email: null,
        currency: null,
        previous_total: null,
        exchange: null,
        bank_account_dest_id: null,
        bank_account_orig_id: null,
        is_intercompany_transfer: false,
        detail: [
          {
            service: null,
            description: null,
            quantity: 1,
            rate: 0,
            amount: 0,
            id: null,
            identifier: null,
          },
          {
            service: null,
            description: null,
            quantity: 1,
            rate: 0,
            amount: 0,
            id: null,
            identifier: null,
          },
        ],
        files: null,
      },
      idCompany: '',
      vendorModalController: false,
      vendors: [],
      termsModalController: false,
      customerModalController: false,
      terms: [],
      categorySidebarController: false,
      customers: [],
      files: [],
      currencies: [
        {
          value: 1,
          text: 'S/',
        },
        {
          value: 2,
          text: '$',
        },
      ],
      deletedFiles: [],
      bankAccounts: [],
      country: '',
      isLoading: false,
    }
  },
  async created() {
    this.onControl = true
    this.isLoading = true
    this.addPreloader()
    if (this.idIncome != null) {
      await this.getIncome()
    }

    this.idCompany = this.$route.params.id
    const data = await CompaniesService.getCompanyCountryById({
      company_id: this.idCompany,
    })
    this.invoice.company_id = this.idCompany
    const { country } = data.data[0]
    this.invoice.income_date = moment().format()
    this.country = country
    this.invoice.currency = country === 'US' ? 2 : 1
    await this.getCustomers()
    await this.getProductsAndServices()
    await this.getTerms()
    this.removePreloader()
    this.isLoading = false
  },
  computed: {
    // eslint-disable-next-line consistent-return
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    totalAmount() {
      let sum = 0
      for (let i = 0; i < this.invoice.detail.length; i++) {
        sum += Number(this.invoice.detail[i].amount)
      }
      return sum.toFixed(2)
    },
    isConfirmed() {
      return this.invoice.status === 2
    },
    isCompanyFromUsa() {
      return this.country === 'US'
    },
    money() {
      for (let i = 0; i < this.currencies.length; i++) {
        if (this.currencies[i].value == this.invoice.currency) {
          return {
            decimal: '.',
            thousands: ',',
            prefix: this.currencies[i].text,
            precision: 2,
            masked: false,
          }
        }
      }
    },
    isAtLeastOneItemAdded() {
      for (let i = 0; i < this.invoice.detail.length; i++) {
        if (this.invoice.detail[i].category != null || this.invoice.detail[i].description || this.invoice.detail[i].amount != 0) {
          return true
        }
      }
      return false
    },
  },
  methods: {
    close() {
      this.$emit('close')
    },
    addLines() {
      for (let i = 0; i < 4; i++) {
        const obj = {
          service: null,
          description: null,
          quantity: 1,
          rate: 0,
          amount: 0,
          id: null,
          identifier:
              new Date().getUTCMilliseconds() + Math.random() * 10000000000,
        }
        this.invoice.detail.push(obj)
      }
    },
    clearAllLines() {
      for (let i = 2; i < this.invoice.detail.length; i++) {
        this.deleteds.push(this.invoice.detail[i].id)
      }
      const index = 1
      this.invoice.detail.splice(
        index + 1,
        this.invoice.detail.length - (index + 1),
      )
    },
    clearData() {
      this.invoice.income_id = null
      this.invoice.company_id = this.idCompany
      this.invoice.customer_id = null
      this.invoice.payment_account = null
      this.invoice.income_date = moment().format()
      this.invoice.payment_method = null
      this.invoice.doc_no = null
      this.invoice.message_on_invoice = null
      this.invoice.message_on_statement = null
      this.invoice.billing_address = null
      this.invoice.type = 1
      this.invoice.status = null
      this.invoice.term_id = null
      this.invoice.due_date = null
      this.invoice.customer_email = null
      this.invoice.previous_total = null
      this.$set(this.invoice, 'detail', [
        {
          service: null,
          description: null,
          quantity: 1,
          rate: 0,
          amount: null,
          id: null,
          identifier: null,
        },
        {
          service: null,
          description: null,
          quantity: 1,
          rate: 0,
          amount: null,
          id: null,
          identifier: null,
        },
      ])
      this.invoice.files = null
      this.isDragging = false
      this.dragCount = 0
      this.files = []
      this.$refs['file-manager'].removeAllFiles()
      this.images = []
    },
    async upload(stat) {
      if (this.isAtLeastOneItemAdded) {
        try {
          const response = await this.showConfirmSwal()
          if (response.isConfirmed) {
            const formData = new FormData()
            this.files.forEach(file => {
              formData.append('images[]', file, file.name)
            })
            this.addPreloader()

            formData.append('header', JSON.stringify(this.invoice))
            formData.append('created_by', this.currentUser.user_id)
            formData.append('status', 1)
            formData.append('total', this.totalAmount)
            formData.append('balance_due', this.totalAmount)
            const headers = {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
              // eslint-disable-next-line func-names
              onUploadProgress: function (progressEvent) {
                this.uploadPercentage = Math.round(
                  (progressEvent.loaded * 100) / progressEvent.total,
                )
              }.bind(this),
            }
            const data = await CompaniesService.saveInvoice(formData, headers)
            if (data.status === 200) {
              if (stat !== 1) {
                this.removePreloader()
                this.$emit('reload')
                this.close()
                this.showSuccessSwal()
              } else {
                this.removePreloader()
                this.clearData()
              }
              if (this.deleteds.length > 0) {
                await CompaniesService.deleteIncomeDetail({
                  deleteds: this.deleteds,
                })
              }
              if (this.deletedFiles.length > 0) {
                await CompaniesService.deleteIncomeFile({
                  deleted_files: this.deletedFiles,
                })
              }
            }
          }
        } catch (e) {
          this.showErrorSwal(e)
          this.removePreloader()
        }
      } else {
        this.showWarningSwal('Important!', 'Please enter at least one line item.')
      }
    },
    deleteFile(index, file) {
      this.invoice.files.splice(index, 1)
      this.deletedFiles.push(file.id)
    },
    generateTotal(item) {
      item.amount = item.quantity * item.rate
    },
    async getTerms() {
      try {
        const data = await CompaniesService.getTerms({
          company_id: this.idCompany,
        })
        if (data.status === 200) {
          this.terms = data.data
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getIncome() {
      try {
        const data = await CompaniesService.getIncome({
          income_id: this.idIncome,
        })
        if (data.status === 200) {
          this.invoice = data.data[0]
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getProductsAndServices() {
      try {
        const data = await CompaniesService.getProductsServicesForInvoices({
          company_id: this.idCompany,
        })
        if (data.status === 200) {
          this.productsAndServices = data.data
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    async getCustomers() {
      try {
        const data = await CompaniesService.getCustomers({
          idcompany: this.idCompany,
        })
        if (data.status === 200) {
          this.customers = data.data
        }
      } catch (e) {
        this.showErrorSwal(e)
      }
    },
    onChangeCustomer() {
      const id = this.customers.findIndex(customer => customer.id === this.invoice.customer_id)
      if (!this.customers[id].billing_address
          && !this.customers[id].billing_city
          && !this.customers[id].billing_state
          && !this.customers[id].billing_zip_code) {
        this.invoice.mailling_address = null
      } else {
        this.invoice.billing_address = `${this.customers[id].billing_address ? this.customers[id].billing_address : ''} ${this.customers[id].billing_city ? this.customers[id].billing_city : ''} ${this.customers[id].billing_state ? this.customers[id].billing_state : ''}
        ${this.customers[id].billing_zip_code ? this.customers[id].billing_zip_code : ''}`
      }
    },
    onChangeProducts(line) {
      for (let i = 0; i < this.productsAndServices.length; i++) {
        if (this.productsAndServices[i].id === Number(line.service)) {
          line.quantity = 1
          line.rate = this.productsAndServices[i].price * line.quantity
          line.amount = line.rate
        }
      }
    },
    deleteInvoiceDetail(index, detail) {
      this.invoice.detail.splice(index, 1)
      if (detail.id) {
        this.deleteds.push(detail.id)
      }
    },
  },
}
</script>
<style>
.tree-modal .modal-dialog {
  max-width: 100%;
  margin: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  max-height: calc(100vh);
  display: flex;
  position: fixed;
  z-index: 100000;
}
@media (min-width: 576px){
  .transaction-modal .modal-dialog-scrollable .modal-content{
    max-height: calc(100vh);
  }
}
</style>
<style scoped>
.input-money-style{
  width: 100%; height: 2.714rem; border: transparent;
}
.mb-half{
  margin-bottom: 0.3rem;
}
.close-but:hover{
  box-shadow: 0 0 11px rgba(33,33,33,.2);
}
@media only screen and (max-width: 720px) {

}
</style>
