import { render, staticRenderFns } from "./BankTransferList.vue?vue&type=template&id=7e3568d3&scoped=true"
import script from "./BankTransferList.vue?vue&type=script&lang=js"
export * from "./BankTransferList.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e3568d3",
  null
  
)

export default component.exports