export default [
  {
    key: 'category',
    sortable: false,
    label: 'Category',
  },
  {
    key: 'name',
    sortable: false,
    label: 'Name',
  },
  {
    key: 'sku',
    sortable: false,
    label: 'SKU',
  },
  {
    key: 'type',
    sortable: false,
    label: 'TYPE',
  },

  {
    key: 'sales_pricing',
    sortable: false,
    label: 'SALES PRICING',
  },
  {
    key: 'cost',
    sortable: false,
    label: 'COST',
  },
  {
    key: 'created_by',
    sortable: false,
    label: 'CREATED BY',
  },

  {
    key: 'actions',
    sortable: false,
    label: 'ACTIONS',
  },

]
