export default [
  {
    key: 'date',
    sortable: false,
    label: 'Date',
  },
  {
    key: 'type_1',
    sortable: false,
    label: 'Type',
  },
  {
    key: 'no',
    sortable: false,
    label: 'NO.',
  },
  {
    key: 'origin_bank',
    sortable: false,
    label: 'Origin Bank',
  },

  {
    key: 'destination_bank',
    sortable: false,
    label: 'DESTINATION BANK',
  },
  {
    key: 'total',
    sortable: false,
    label: 'TOTAL',
  },
  {
    key: 'type_2',
    sortable: false,
    label: 'TYPE',
  },

  {
    key: 'created_by',
    sortable: false,
    label: 'CREATED BY',
  },

]
